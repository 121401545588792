import React from 'react';
import { Link } from 'react-router-dom';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));


const Navbar = (props) => {
  const classes = useStyles();

  

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
      <div className={classes.toolbar} />
      <List>
        {props.pages.map( (item, index) => (
          <ListItem button key={item.text} component={Link} to={item.path}>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>


  );
}

export default Navbar;
