import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import Home from './Home';
import About from './About';
import Blog from './Blog';


import Layout from '../components/Layout';
const Pages = () => {
  
  const items = [
    { text: "Home", path: "/" },
    { text: "About", path: "/about" },
    { text: "Blog", path: "/blog" },
  ]

  return (
    <Router>
      <Layout pages={items}>
        <Route exact path='/' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/blog' component={Blog} />
      </Layout>
    </Router>
    

  )
};

export default Pages;
