import React from 'react';

import Typography from '@material-ui/core/Typography';

const Blog = (props) => {

  return (
    <Typography variant="h3">
      Blog!
    </Typography>
  );
};

export default Blog;
