import React, { useEffect } from 'react';

import Typography from '@material-ui/core/Typography';


const Home = (props) => {
  useEffect(() => {
    document.title = '4ddigital';
  })


  return (
    <div>
      <Typography variant="h3">
        Home!
      </Typography>
      <Typography variant="p">
        Welcome to the future website of 4ddigital. Please excuse the construction.
      </Typography>
    </div>

  );
};

export default Home;
