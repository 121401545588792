import React from 'react';
import ReactDOM from 'react-dom';
import 'typeface-roboto';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';

import Pages from './pages';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#4d5057'
    },
    secondary: {
      main: '#6c99bb'
    },
  }
});


const App = () => {

  return (
    <ThemeProvider theme={theme} >
      <Pages />
    </ThemeProvider>
  );
};

ReactDOM.render( <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
