import React from 'react';

import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 30,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1
  },
}));


const HeaderBar = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root} >
      <AppBar position="fixed" className={classes.appBar}>
        <Typography variant="h4" className={classes.title} >
          4ddigital
        </Typography>
      </AppBar>
    </div>
  );
}

export default HeaderBar;

