import React from 'react';
import 'typeface-roboto';

import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';

import HeaderBar from './HeaderBar';
import Navbar from './Navbar';

const useStyles = makeStyles( (theme) => ({
  wrapper: {
    display: 'flex',
  },
  content: {
    width:  'calc(100% - 200px) !important',
    position: 'relative',
    top: 80,
    margin: 15,
  }
}));

const Layout = ( props ) => {
  const classes = useStyles();


  return (
    <div>
      <CssBaseline />
      <HeaderBar />
      <div className={classes.wrapper}>
        <Navbar pages={props.pages} />
        <div className={classes.content}>
          {props.children}
        </div>
      </div>
    </div>
      );
}

export default Layout;
